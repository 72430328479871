import axios, {
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from 'axios';
import Qs from 'qs';
import { DEFAULT_LANGUAGE, TYPE_COOKIE } from './constants';
import { store } from '../';
import { objectType } from 'types';
import { getCookie, removeCookie } from './cookies';
import { notify } from 'reapop';
import { appActions } from 'app/pages/App/slice';
// import { appActions } from 'app/pages/App/slice';

export const KEY_NOTIFICATION = 'KEY';
export const KEY_NOTIFICATION_PUT = 'PUT';
export const KEY_NOTIFICATION_POST = 'POST';
export const KEY_NOTIFICATION_DELETE = 'DELETE';

const onSuccessInterceptorRequest = async (config: AxiosRequestConfig) => {
  const token = await getCookie(TYPE_COOKIE.TOKEN);
  const language = (await getCookie(TYPE_COOKIE.LANGUAGE)) || DEFAULT_LANGUAGE;

  config.url = `/${language}/api/${config.url}`;

  if (token && config && config.headers)
    config.headers.Authorization = `${token}`;

  config.paramsSerializer = (params: any) =>
    Qs.stringify(params, {
      arrayFormat: 'indices',
    });
  return config;
};
const onErrorInterceptorRequest = (error: AxiosError) => Promise.reject(error);

let notif;

const onErrorInterceptorResponse = (error: AxiosError<objectType>) => {
  if (error.response && error.response.status) {
    // error.response.data?.errors?.length &&
    //   store.dispatch(
    //     notify(error.response.data?.errors[0].msg, 'error', {
    //       title: 'Oops',
    //       dismissAfter: 5000,
    //     }),
    //   );
    store.dispatch(
      notify(
        error.response?.data?.message ? error.response?.data?.message : '',
        'error',
        {
          title: 'Oops',
          dismissAfter: 5000,
        },
      ),
    );
    if (error.response.status === 401) {
      removeCookie(TYPE_COOKIE.TOKEN);
      store.dispatch(appActions.logout());
    }
  }
  return Promise.reject(error);
};
const onSuccessInterceptorResponse = (response: AxiosResponse) => {
  if (
    response.status === 200 &&
    response.config.method !== 'get' &&
    !response.config.url?.includes('customer')
  ) {
    if (!notif) {
      notif = store.dispatch(
        notify('Thành công.', 'success', {
          title: 'Success',
          dismissAfter: 2000,
        }),
      );

      setTimeout(() => {
        notif = null;
      }, 2000);
    }
  }
  return response;
};

axios.defaults.headers.post['Accept'] = 'application/json';

const _axios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  timeout: 120 * 1000,
  // withCredentials: true, // Check cross-site Access-Control
});

_axios.interceptors.request.use(
  onSuccessInterceptorRequest,
  onErrorInterceptorRequest,
);

_axios.interceptors.response.use(
  onSuccessInterceptorResponse,
  onErrorInterceptorResponse,
);

/**
 *
 * @NOTE primary methods axios
 *
 */
class AxiosXHRConstructor {
  axiosInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.$get = this.$get.bind(this);
    this.$post = this.$post.bind(this);
    this.$put = this.$put.bind(this);
    this.$delete = this.$delete.bind(this);
  }
  public $get(
    url: string,
    params?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.get(url, {
      ...{ params },
      ...config,
    });
  }
  public $post(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.post(url, data, config);
  }
  public $put(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.put(url, data, config);
  }
  public $delete(url: string, data?: objectType): AxiosPromise {
    // return this.axiosInstance.delete(url, {
    //   data,
    // });

    /**
     * @hotfix {https://github.com/axios/axios/issues/3220}
     */
    return this.axiosInstance.request({
      method: 'delete',
      url,
      data,
    });
  }
}

export const BaseXHR = new AxiosXHRConstructor(_axios);

export default _axios;
