import { objectType } from 'types';
import { BaseXHR } from 'utils/axios';

export const getCartApi = (data: objectType) => BaseXHR.$get('cart/get', data);

export const updateCartApi = (data: objectType) =>
  BaseXHR.$get('cart/update', data);

export const addCartApi = (data: objectType) => BaseXHR.$get('cart/add', data);

export const removeCartApi = (data: objectType) =>
  BaseXHR.$get('cart/remove', data);
