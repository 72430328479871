import {
  addCartApi,
  getCartApi,
  removeCartApi,
  updateCartApi,
} from 'app/services/cartApi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_CART, GET_CART, REMOVE_CART, UPDATE_CART } from '.';

export function* getCart({ payload }: any) {
  try {
    const { data } = yield call(getCartApi, payload);
    yield put(GET_CART.success(data));
  } catch (err) {
    yield put(GET_CART.failure());
  }
}

export function* updateCart({ payload }: any) {
  try {
    const { data } = yield call(updateCartApi, payload);
    yield put(UPDATE_CART.success(data));
  } catch (err) {
    yield put(UPDATE_CART.failure());
  }
}

export function* addCart({ payload }: any) {
  try {
    const { data } = yield call(addCartApi, payload);
    yield put(ADD_CART.success(data));
  } catch (err) {
    yield put(ADD_CART.failure());
  }
}

export function* removeCart({ payload }: any) {
  try {
    const { data } = yield call(removeCartApi, payload);
    yield put(REMOVE_CART.success(data));
  } catch (err) {
    yield put(REMOVE_CART.failure());
  }
}

export function* cartSaga() {
  yield takeLatest(GET_CART.TRIGGER, getCart);
  yield takeLatest(ADD_CART.TRIGGER, addCart);
  yield takeLatest(REMOVE_CART.TRIGGER, removeCart);
  yield takeLatest(UPDATE_CART.TRIGGER, updateCart);
}
