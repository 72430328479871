import { I_OptionsGetList } from 'types';

export enum TYPE_LOCAL_STORAGE {
  URL_REDIRECT = 'b2c-url-redirect',
  CART = 'b2c-cart',
}

export enum TYPE_COOKIE {
  TOKEN = 'b2c-token',
  LANGUAGE = 'b2c-language',
}

export const LIMIT_PAGE_SIZE = 6;

export const default_options_get_list: I_OptionsGetList = {
  limit: LIMIT_PAGE_SIZE,
  page: 1,
  search: '',
  order_by: '',
  order: 'desc',
};

export const DEFAULT_LANGUAGE = 'vi';
