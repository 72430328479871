import { appActions } from 'app/pages/App/slice';
import {
  selectIsShowDialogLogin,
  selectIsShowDialogRegister,
  selectLanguage,
} from 'app/pages/App/slice/selectors';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_COOKIE } from 'utils/constants';
import { setCookie } from 'utils/cookies';

export const useGlobalFunction = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLanguage);
  const isShowDialogLogin = useSelector(selectIsShowDialogLogin);
  const isShowDialogRegister = useSelector(selectIsShowDialogRegister);

  const onLogout = useCallback(() => dispatch(appActions.logout()), [dispatch]);

  const onChangeLanguage = useCallback(
    (language: 'vi' | 'en') => {
      const currentUrl = window.location.pathname.replaceAll(
        language === 'vi' ? '/en' : 'vi',
        '',
      );
      const baseUrl = language === 'vi' ? '' : '' + language;
      dispatch(appActions.onChangeLanguage(language));
      const url = baseUrl + currentUrl + window.location.search;
      window.history.pushState(null, '', url);
      window.location.reload();
    },
    [dispatch],
  );

  const onOpenDialogLogin = useCallback(() => {
    dispatch(appActions.openDialogLogin());
  }, [dispatch]);

  const onOpenDialogRegister = useCallback(() => {
    dispatch(appActions.openDialogRegister());
  }, [dispatch]);

  const onCloseDialogAuth = useCallback(() => {
    dispatch(appActions.closeDialogAuth());
  }, [dispatch]);

  const onLoginSuccess = useCallback(
    async token => {
      await setCookie(TYPE_COOKIE.TOKEN, token);
      dispatch(appActions.changeIsLogged({ isLogged: true }));
      onCloseDialogAuth();
    },
    [dispatch, onCloseDialogAuth],
  );

  return useMemo(
    () => ({
      locale,
      onChangeLanguage,

      onLogout,
      onLoginSuccess,
      isShowDialogLogin,
      isShowDialogRegister,
      onOpenDialogLogin,
      onOpenDialogRegister,
      onCloseDialogAuth,
    }),
    [
      locale,
      onChangeLanguage,

      onLogout,
      onLoginSuccess,
      isShowDialogLogin,
      isShowDialogRegister,
      onOpenDialogLogin,
      onOpenDialogRegister,
      onCloseDialogAuth,
    ],
  );
};
