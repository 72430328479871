import { selectIsLogged } from 'app/pages/App/slice/selectors';
import { I_Ticket } from 'app/pages/Catalog/slice/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TYPE_LOCAL_STORAGE } from 'utils/constants';
import { useDebounce } from 'utils/hooks/useDebounce';
import { getItem, removeItem, setItem } from 'utils/localStorage';
import { GET_CART, UPDATE_CART, useCartSlice } from '../slice';
import {
  selectDatesSelectedToCheckout,
  selectListCart,
} from '../slice/selectors';
import { I_CartItem } from '../slice/types';

export interface I_CartGroupByDate {
  [key: string]: { [key: string]: I_CartItem[] };
}

export interface I_CartItemLocal {
  id: number;
  amount: number;
  performance?: number;
  date: string;
}

export const useCart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isGetCartSuccess = useRef(false);

  const { actions: cartActions } = useCartSlice();

  const listCart = useSelector(selectListCart);
  const isLogged = useSelector(selectIsLogged);
  const listDateSelectedToCheckout = useSelector(selectDatesSelectedToCheckout);

  const [cartLocal, setCartLocal] = useState<Map<string, I_CartItemLocal>>(
    new Map([]),
  );

  const cartLocalDebounce = useDebounce({ value: cartLocal, delay: 1000 });

  // NOTE: convert cartLocal to format full data (used on cart page) aim to update amount of ticket faster instead must wait listCart on redux
  const listCartLocalGroupByDate: I_CartGroupByDate = useMemo(() => {
    const cart: I_CartItem[] = [];
    const cartLocalIterator = cartLocal[Symbol.iterator]();

    for (const [, value] of cartLocalIterator) {
      const data = listCart.find(
        item =>
          item.product.id === value.id &&
          item.date === value.date &&
          item.performance?.id === value.performance,
      );
      if (data) cart.push({ ...data, amount: '' + value.amount });
    }

    return cart.reduce((result, item) => {
      if (item && item.performance?.id) {
        if (!result[item.date]) result[item.date] = {};
        if (result[item.date][item.performance?.id])
          result[item.date][item.performance?.id].push(item);
        else {
          result[item.date][item.performance?.id] = [item];
        }
      }
      return result;
    }, {} as I_CartGroupByDate);
  }, [cartLocal, listCart]);

  // convert listCart on redux same to format on local storage
  const listCartConverted = useMemo(
    () =>
      listCart.reduce((result, item) => {
        result.set(
          item.product.id +
            '_' +
            (item.performance?.id || '') +
            '_' +
            item.date,
          {
            id: item.product.id,
            amount: +item.amount,
            performance: item.performance?.id || undefined,
            date: item.date,
          },
        );
        return result;
      }, new Map([]) as Map<string, I_CartItemLocal>),
    [listCart],
  );

  const totalAmount = useMemo(
    () => listCart.reduce((result, item) => result + +item.amount, 0),
    [listCart],
  );

  const totalPriceOriginal = useMemo(
    () =>
      listCart.reduce(
        (result, item) => result + item.originalPrice * +item.amount,
        0,
      ),
    [listCart],
  );

  const totalPrice = useMemo(
    () =>
      listCart.reduce((result, item) => result + item.price * +item.amount, 0),
    [listCart],
  );

  const onChangeAmountProductOnCartLocal = useCallback(
    (item: I_CartItemLocal, value: number) => {
      isGetCartSuccess.current = true;
      setCartLocal(
        prev =>
          new Map(
            prev.set(item.id + '_' + item.performance + '_' + item.date, {
              id: item.id,
              amount: value,
              date: item.date,
              performance: item.performance || undefined,
            }),
          ),
      );
    },
    [],
  );

  const onAddCartItemLocal = useCallback(
    (item: I_Ticket, value: number, date: string, performance: number) => {
      isGetCartSuccess.current = true;
      setCartLocal(
        prev =>
          new Map(
            prev.set(item.id + '_' + performance + '_' + date, {
              id: item.id,
              amount: value,
              date,
              performance,
            }),
          ),
      );
    },
    [],
  );

  const onRemoveCartItemLocal = useCallback((key: string) => {
    isGetCartSuccess.current = true;
    setCartLocal(prev => {
      const newCart = new Map(prev);
      newCart.delete(key);
      return newCart;
    });
  }, []);

  const onRemoveCartItemsLocal = useCallback((keys: string[]) => {
    isGetCartSuccess.current = true;
    setCartLocal(prev => {
      const newCart = new Map(prev);
      keys.forEach(key => newCart.delete(key));
      return newCart;
    });
  }, []);

  const onSelectDatesToCheckout = useCallback(
    (keys: string[]) =>
      dispatch(cartActions.onSetDatesSelectedToCheckout(keys)),
    [cartActions, dispatch],
  );

  const onGoToCheckoutPage = useCallback(() => {
    onSelectDatesToCheckout(Object.keys(listCartLocalGroupByDate));
    history.push('/checkout');
  }, [onSelectDatesToCheckout, listCartLocalGroupByDate, history]);

  const onSetCart = useCallback(
    (cart: I_CartItem[]) => dispatch(cartActions.onSetCart(cart)),
    [cartActions, dispatch],
  );

  const onUpdateCart = useCallback(
    (cart: Map<string, I_CartItemLocal>) => {
      const dataCart: any = [];
      cart.forEach(value => {
        dataCart.push(value);
      });
      if (!isLogged)
        setItem(
          TYPE_LOCAL_STORAGE.CART,
          JSON.stringify(dataCart, function (k, v) {
            return v === undefined ? null : v;
          }),
        );
      dispatch(UPDATE_CART({ items: dataCart }));
    },
    [dispatch, isLogged],
  );

  const onGetCart = useCallback(
    async (isLogged: boolean) => {
      const cartLocal = await getItem(TYPE_LOCAL_STORAGE.CART);
      dispatch(
        GET_CART(cartLocal ? { items: JSON.parse(cartLocal) } : undefined),
      );
      if (isLogged && cartLocal) removeItem(TYPE_LOCAL_STORAGE.CART);
    },
    [dispatch],
  );

  useEffect(() => {
    if (isGetCartSuccess.current) onUpdateCart(cartLocal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLocalDebounce]);

  useEffect(() => {
    if (!isGetCartSuccess.current) setCartLocal(listCartConverted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCartConverted.size]);

  useEffect(() => {
    // NOTE: remove checked date on cart when item removed

    if (listDateSelectedToCheckout.length && isGetCartSuccess.current)
      onSelectDatesToCheckout(
        listDateSelectedToCheckout.filter(
          item => Object.keys(listCartLocalGroupByDate).indexOf(item) > -1,
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCartLocalGroupByDate]);

  useEffect(
    () => () => {
      isGetCartSuccess.current = false;
    },
    [],
  );

  return useMemo(
    () => ({
      cartActions,
      cartLocal,
      cartLocalDebounce,
      onChangeAmountProductOnCartLocal,
      onAddCartItemLocal,
      onRemoveCartItemLocal,
      onRemoveCartItemsLocal,

      listCart,
      listCartConverted,
      listCartLocalGroupByDate,
      totalAmount,
      totalPrice,
      totalPriceOriginal,
      listDateSelectedToCheckout,
      onGetCart,
      onSetCart,
      onUpdateCart,
      onGoToCheckoutPage,
      onSelectDatesToCheckout,
    }),
    [
      cartActions,
      cartLocal,
      cartLocalDebounce,
      onChangeAmountProductOnCartLocal,
      onAddCartItemLocal,
      onRemoveCartItemLocal,
      onRemoveCartItemsLocal,

      listCart,
      listCartLocalGroupByDate,
      listCartConverted,
      totalAmount,
      totalPrice,
      totalPriceOriginal,
      listDateSelectedToCheckout,
      onGetCart,
      onSetCart,
      onUpdateCart,
      onGoToCheckoutPage,
      onSelectDatesToCheckout,
    ],
  );
};
