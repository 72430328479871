import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const notification = (state: RootState) => state.notifications || initialState;

const selectSlice = (state: RootState) => state.app || initialState;

export const selectApp = createSelector([selectSlice], state => state);

export const selectNotifications = createSelector(
  [notification],
  state => state,
);

export const selectIsLoading = createSelector(
  [selectApp],
  state => state.loading,
);

export const selectIsSuccess = createSelector(
  [selectApp],
  state => state.success,
);

/**
 * NOTE: used for check isLogged is undefined or not (undefined mean we won't do anything until we get by true or false)
 */
export const selectIsLoggedNotEqualUndefined = createSelector(
  [selectApp],
  state => state.isLogged !== undefined,
);

export const selectIsLogged = createSelector([selectApp], state =>
  state.isLogged === undefined ? false : state.isLogged,
);

export const selectLanguage = createSelector(
  [selectApp],
  state => state.language,
);

export const selectIsShowDialogLogin = createSelector(
  [selectApp],
  state => state.isShowDialogLogin,
);

export const selectIsShowDialogRegister = createSelector(
  [selectApp],
  state => state.isShowDialogRegister,
);

export const selectTranslations = createSelector(
  [selectApp],
  state => state.translations,
);

export const selectLayout = createSelector([selectApp], state => state.layout);

export const selectLayoutHeader = createSelector(
  [selectLayout],
  layout => layout?.header,
);

export const selectLayoutFooter = createSelector(
  [selectLayout],
  layout => layout?.footer,
);

export const selectLayoutCodeConfig = createSelector(
  [selectLayout],
  layout => layout?.codeConfig,
);

export const selectInfoUser = createSelector(
  [selectApp],
  state => state.infoUser,
);

export const selectIsError = createSelector([selectApp], state => state.error);
