import { I_PayloadGetListOrder } from 'app/pages/Account/ListOrder/slice/types';
import { BaseXHR } from 'utils/axios';

export const loginApi = (data: FormData) =>
  BaseXHR.$post('customer/login', data);

export const registerApi = (data: FormData) =>
  BaseXHR.$post('customer/register', data);

export const logoutApi = () => BaseXHR.$post('b2c/v1/account/logout');

export const getMeApi = () => BaseXHR.$get('b2c/v1/account/get');

export const getListOrderApi = (payload: I_PayloadGetListOrder) =>
  BaseXHR.$get('v1/order/listing', payload);
