/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Redirect, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';
import { OverlayThumb } from 'app/components/OverlayThumb/Loadable';
import CheckNewVersion from './components/CheckNewVersion';
import { LoadingOutlined } from '@ant-design/icons';
import { AppWrapper } from './styled';
import { GET_LAYOUT, GET_ME, GET_TRANS, useAppSlice } from './slice';
import NotificationsSystem, {
  atalhoTheme,
  setUpNotifications,
  dismissNotification,
} from 'reapop';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsLogged,
  selectNotifications,
  selectIsLoggedNotEqualUndefined,
  selectLayout,
} from './slice/selectors';
import { useGlobalFunction } from './hooks/useGlobalFunction';
import { useCart } from '../Cart/hooks/useCart';

import { auth } from 'utils/helper';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { NormalRoute, ProtectedRoute } from 'app/components/RouteGuard';
import { Home } from '../Home/Loadable';
import { Layout } from 'app/components/Layout/Loadable';
import { Checkout } from '../Checkout/Loadable';
import { PaymentCallback } from '../PaymentCallback/Loadable';
import { PrintTickets } from '../PaymentCallback/PrintTickets/Loadable';
import { DialogLogin, DialogRegister } from './components/Loadable';
import { CatalogDetailShow } from '../Catalog/CatalogDetailShow/Loadable';
import { Catalog } from '../Catalog/Loadable';
import { Account } from '../Account/Loadable';
import { Cart } from '../Cart/Loadable';
import { getCookie } from 'utils/cookies';
import { TYPE_COOKIE } from 'utils/constants';
import { Campaign } from '../Campaign/Loadable';

// run this function when your application starts before creating any notifications
setUpNotifications({
  defaultProps: {
    allowHTML: true,
    dismissible: true,
    dismissAfter: 2000,
    transition: 'slide',
    position: 'bottom-right',
    showDismissButton: true,
  },
});

export function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { actions } = useAppSlice();

  const layout = useSelector(selectLayout);
  const isLogged = useSelector(selectIsLogged);
  const isLoggedNotEqualUndefined = useSelector(
    selectIsLoggedNotEqualUndefined,
  );
  const notifications = useSelector(selectNotifications);

  const {
    isShowDialogLogin,
    onOpenDialogLogin,
    onOpenDialogRegister,
    onCloseDialogAuth,
    onLoginSuccess,
    isShowDialogRegister,
  } = useGlobalFunction();

  const { onGetCart } = useCart();

  useEffect(() => {
    let language = window.location.pathname.includes('/en')
      ? ('en' as const)
      : ('vi' as const);
    if (!language) language = getCookie(TYPE_COOKIE.LANGUAGE);
    dispatch(actions.onChangeLanguage(language));

    dispatch(GET_TRANS());
    dispatch(GET_LAYOUT());

    dispatch(actions.changeIsLogged({ isLogged: auth() }));
    return () => {
      dispatch(actions.resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dispatch]);

  const baseUrl = useMemo(() => {
    let language = window.location.pathname.includes('/en') ? 'en' : 'vi';
    if (!language) language = getCookie(TYPE_COOKIE.LANGUAGE);
    return language === 'vi' ? '' : '/' + language;
  }, []);

  useEffect(() => {
    if (isLoggedNotEqualUndefined) {
      isLogged && dispatch(GET_ME());
      onGetCart(isLogged);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedNotEqualUndefined, isLogged]);

  return (
    <>
      <Helmet
        titleTemplate={`%s - ${layout?.pageConstruct?.title}`}
        defaultTitle={`${layout?.pageConstruct?.title}`}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content={`${layout?.pageConstruct?.description}`}
        />
      </Helmet>
      <CheckNewVersion>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading)
            return (
              <OverlayThumb icon={<LoadingOutlined />} label="Loading..." />
            );
          if (!loading && !isLatestVersion) refreshCacheAndReload();
          return (
            <BrowserRouter basename={baseUrl}>
              <AppWrapper>
                <Layout>
                  <Switch>
                    <NormalRoute path="/" exact component={Home} />
                    <NormalRoute path="/cart" exact component={Cart} />
                    <NormalRoute path="/checkout" component={Checkout} />
                    <NormalRoute path="/catalog" exact component={Catalog} />
                    <NormalRoute
                      path="/catalog/:id"
                      exact
                      component={CatalogDetailShow}
                    />
                    <NormalRoute
                      path="/payment-callback"
                      component={PaymentCallback}
                    />
                    <NormalRoute
                      path="/print-tickets/:id"
                      component={PrintTickets}
                    />
                    <NormalRoute path="/campaign" component={Campaign} />
                    <ProtectedRoute path="/account" component={Account} />
                    {/* <Redirect to="/" /> */}
                  </Switch>
                </Layout>

                <NotificationsSystem
                  theme={atalhoTheme}
                  notifications={notifications}
                  dismissNotification={id => dispatch(dismissNotification(id))}
                />
              </AppWrapper>

              {isShowDialogLogin && (
                <DialogLogin
                  isShow={isShowDialogLogin}
                  onLoginSuccess={onLoginSuccess}
                  onCloseDialog={onCloseDialogAuth}
                  onOpenDialogRegister={onOpenDialogRegister}
                />
              )}
              {isShowDialogRegister && (
                <DialogRegister
                  isShow={isShowDialogRegister}
                  onRegisterSuccess={onLoginSuccess}
                  onCloseDialog={onCloseDialogAuth}
                  onOpenDialogLogin={onOpenDialogLogin}
                />
              )}
            </BrowserRouter>
          );
        }}
      </CheckNewVersion>
      <GlobalStyle />
    </>
  );
}
